/*
* ===================================================
*     Responsive collapse
* ===================================================
*/

@each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    .expand#{$infix} {
        @include media-breakpoint-up($next) {
            display: block !important;
        }
    }
}

.block-toggler {
    display: block;
    position: relative;
    color: $body-color;
    font-weight: bold;

    &[aria-expanded="true"],
    &:focus {
        text-decoration: none;
        color: $body-color;
    }

    &:focus,
    &:hover {
        text-decoration: none;
        color: $body-color;
    }

    .block-toggler-icon {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);

        &:before,
        &:after {
            position: absolute;
            content: "";
            transition: transform 0.25s ease-out;
            background-color: $body-color;
        }

        /* Vertical line */
        &:before {
            top: 0;
            left: 50%;
            width: 2px;
            height: 100%;
            margin-left: -1px;
        }

        /* horizontal line */
        &:after {
            top: 50%;
            left: 0;
            width: 100%;
            height: 2px;
            margin-top: -1px;
        }

    }

    &[aria-expanded="true"] {
        .block-toggler-icon {
            &:before {
                transform: rotate(90deg);
            }

            &:after {
                transform: rotate(180deg);
            }
        }
    }

    &:focus,
    &:hover,
    &[aria-expanded="true"] {
        .block-toggler-icon {

            &:before,
            &:after {
                background-color: $body-color;
            }
        }

    }
}