/*
* ===================================================
*     Category sidebar
* ===================================================
*/

.sidebar-block {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $gray-200;

    &:last-of-type {
        border-bottom: none;
    }

    &:first-of-type {
        padding-top: 0;
    }
}

.sidebar-heading {
    margin-bottom: 1.2rem;
    color: theme-color('dark');
}

.sidebar-badge {
    margin-top: .2rem;
    margin-bottom: .2rem;
    padding: 0 0.8rem;
    color: $gray-800;
    border-radius: 4rem;
    background: $gray-200;
    font-size: 0.7em;
}

.nouislider-values {
    display: flex;
    margin-top: 1rem;
    color: $gray-600;
    font-size: $font-size-sm;
    justify-content: space-between;
}

/* Sidebar Icon Menu */


.sidebar-icon-menu {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    padding-left: 0;
    flex-wrap: wrap;
    list-style: none;
}

a.sidebar-icon-menu-link {
    text-decoration: none;
    color: theme-color('dark');
    font-size: $font-size-sm;

}

.sidebar-icon-menu-item {
    position: relative;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    cursor: pointer;
    border-bottom: 1px solid $gray-200;

    &:last-of-type {
        border-bottom: none;
    }

    &[data-toggle="collapse"] {
        &::before {
            display: inline-block;
            position: absolute;
            top: 2.2rem;
            right: 0;
            content: '\f105';
            transform: translateY(-50%);
            font-family: "Font Awesome\ 5 Free";
            font-weight: 900;
        }
    }

    &[aria-expanded="true"] {
        &::before {
            content: '\f107';
        }
    }
}

.sidebar-icon {
    width: 2rem !important;
    height: 2rem !important;
    margin-right: 1rem;
}

.sidebar-icon-menu-count {
    margin-top: -1px;
    color: theme-color('muted');
    font-size: $font-size-sm;
}

.sidebar-icon-submenu {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 3rem;
    font-size: $font-size-sm;
}

.sidebar-icon-submenu-item {
    margin-bottom: .5rem;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.sidebar-icon-submenu-link {
    color: $gray-600;
}