/*
* ===================================================
*     Cart overview modal
* ===================================================
*/

.sidebar-cart-content {
    padding-bottom: 200px;
}

.sidebar-cart-body {
    padding-right: 3rem;
    padding-left: 3rem;
}

.sidebar-cart-footer {
    position: fixed;    
    bottom: 0;
    width: 100%;
    height: 200px;
    padding-right: 3rem;
    padding-left: 3rem;
    border: none;
    z-index: 10005;
    justify-content: flex-start;
    align-content: center
}

.sidebar-cart-product-wrapper {
    overflow-y: auto;
    max-height: calc(100vh - 312px);
    margin-right: -3rem;
    padding-right: 2.6rem;
}

.svg-icon.sidebar-cart-icon {
    width: 24px;
    height: 24px;
}


.navbar-cart-product {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid $gray-200;

    &:first-of-type {
        padding-top: 0;
    }

    &:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
    }

    .navbar-cart-product-image {
        max-width: 80px;
    }

    .navbar-cart-product-link {
        font-weight: bold;
    }
}

.navbar-cart-total {
    display: flex;
    margin-top: .5rem;
    margin-bottom: .5rem;
    padding: 0.7rem 1rem;
    border-top: 1px solid $gray-200;
    border-bottom: 1px solid $gray-200;
    font-size: $font-size-sm;
    justify-content: space-between;
}