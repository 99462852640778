/*
* ===================================================
*     Swiper additional components
* ===================================================
*/
.swiper-thumbs {
    display: flex;
    margin: 0 -5px;
    padding-top: 10px;

}

.swiper-thumb-item {
    max-width: 100px;
    margin: 0 5px;
    padding: 0;
    transition: all .5s;
    opacity: .5;
    border: $btn-border-width solid transparent;
    background-color: transparent;

    &:focus,
    &.focus {
        outline: 0;
    }

    &.active {
        opacity: 1;
    }
}


.swiper-container-mx-negative {
    margin-right: -8px;
    margin-left: -8px;
}

.swiper-nav {

    .swiper-button-prev,
    .swiper-button-next {
        transition: $transition-base;
        opacity: .3;

        &:hover,
        &:focus {
            opacity: 1;
        }
    }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    right: auto;
    left: 30px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'#{escape-for-svg($swiperColorDark)}'%2F%3E%3C%2Fsvg%3E");
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 30px;
    left: auto;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'#{escape-for-svg($swiperColorDark)}'%2F%3E%3C%2Fsvg%3E");
}

.swiper-pagination-white {
    .swiper-pagination-bullet {
        opacity: .6;
        background: #fff;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #fff;
    }
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: $swiperColor;
}

.swiper .swiper-pagination-bullet-active {
    opacity: 1;
    background: $swiperColor;
}

.swiper-pagination-progressbar {

    .swiper-pagination-progressbar-fill {
        background: $swiperColor;
    }
}


.swiper-outside-nav {
    display: none;
    $showOutsideNav: map-get($grid-breakpoints, "xl") + 200px;

    @media (min-width: $showOutsideNav) {
        display: block;
    }

    .swiper-button-prev {
        left: -100px;
    }

    .swiper-button-next {
        right: -100px;
    }

    &.align-with-products {

        .swiper-button-prev,
        .swiper-button-next {
            top: 120px;
        }
    }
}