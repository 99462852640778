// theme mixins

@import "mixins-custom";
@import "functions";

// theme components
@import "breadcrumb";
@import "navbar";
@import "hero";
@import "topbar";
@import "detail";
@import "products";
@import "sidebar";
@import "forms";
@import "services";
@import "type";
@import "pagination";
@import "sidebar-modal";
@import "sidebar-cart";
@import "quickview";
@import "map";
@import "cart";
@import "icons";
@import "avatar";
@import "collapse-responsive";

// 3rd party plugins styling
@import "image-zoom";
@import "nouislider";
@import "bootstrap-select";
@import "swiper";
@import "aos";
@import "photoswipe";

// utils + docs

@import "other"; 
@import "utils"; 
@import "ie"; 
@import "docs";