/*
* ===================================================
*     Custom form controls
* ===================================================
*/

// 1. Label
.form-label {
    color: $gray-900;
    font-weight: bold;
}

.custom-control-label.text-sm {
    padding-top: 2px;
}

// 2. Colour radio/checkbox element
// Wrapper
.colours-wrapper {
    position: relative;
}

// Hide the actual input, not using display:none bcs of the form validation
.input-invisible {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
}

// Colour button
.btn-colour {
    display: inline-block;
    position: relative;
    width: 28px;
    height: 28px;
    margin-bottom: 0;
    cursor: pointer;
    transition: border-color .1s;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 5px #fff, 0 0 0 1px $gray-400;

    &:hover {
        box-shadow: inset 0 0 0 5px #fff, 0 0 0 1px $gray-600;
    }

    &.active {
        box-shadow: inset 0 0 0 5px #fff, 0 0 0 2px $gray-700;
    }
}


// 3. Underlined form control, i.e. Keep only border bottom
.form-control.form-control-underlined {
    padding-left: 0;
    transition: all .5s;
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: transparent;

    &:focus {
        border-color: theme-color('dark');
        box-shadow: none;

        &~.input-group-append>.btn-underlined {
            border-bottom-color: theme-color('dark');
        }
    }
}

.btn.btn-underlined {
    transition: all .5s;
    border-top: none;
    border-right: none;
    border-bottom-color: $input-border-color;
    border-left: none;
    background-color: transparent;

    &:focus {
        box-shadow: none;
    }
}

.input-group-underlined {

    &:focus,
    &:hover {
        .btn-underlined {
            border-bottom-color: theme-color('dark');
        }

        .form-control-underlined {
            border-bottom-color: theme-color('dark');
        }
    }
}

// 4. no shadow on focus
.focus-shadow-0 {
    &:focus {
        box-shadow: none !important;
    }
}
