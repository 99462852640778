/*
* ===================================================
*    Bootstrap elements additional styles
* ===================================================
*/

.card {
    box-shadow: $card-shadow;
}

.nav-link.active {
    color: theme-color('primary');
}


.list-group {
    border-radius: $list-group-border-radius;
}

.close-absolute {
    position: absolute;
    top: 1rem;
    right: 1rem;
    transition: all linear 0.1s;
    z-index: $zindex-modal + 1;

    &:hover {
        transform: rotate(90deg);
    }

    &.close-centered {
        top: 50%;
        transform: translateY(-50%);

        &:hover {
            transform: translateY(-50%) rotate(90deg);
        }
    }
}

.close-rotate {
    transition: all linear 0.1s;

    &:hover {
        transform: rotate(90deg);
    }
}

.close.text-inherit {
    &:hover {
        color: inherit;
    }
}

.dropdown-toggle {
    &::after {
        display: inline-block;
        margin-left: $caret-spacing;
        content: '\f107';
        transition: all linear 0.25s;
        transform: rotate(0);
        vertical-align: -1px;
        font-family: "Font Awesome\ 5 Free";
        font-weight: 900;
    }

    &[aria-expanded="true"] {
        &::after {
            transform: rotate(-180deg);
        }
    }
}