// this is the place for your components / CSS

/* responsive background image on homepage */
[data-responsive-background-image] {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100%;
  /* padding-bottom: 56.25% */
}

[data-responsive-background-image] img {
  display: none;
}

[data-toggle="zoom"] img,#map-image img {
  cursor: zoom-in;
}

/* homepage big hero */
#home-headline .btn, #home-headline {
  font-size: 1.5vw;
}

#home-headline h1 {
    font-size: 2vw;
    line-height: 1;
    font-weight: 500;
    color: $emergency;
    text-shadow: 0.15vw 0.15vw 5px #000;
    letter-spacing: -0.1vw;
    font-family: $font-family-base;
}


#home-headline h2 {
  font-size: 5vw;
  line-height: 1;
  font-weight: 500;
  text-shadow: .15vw .15vw $black;
}

@include media-breakpoint-down(lg) {
  #home-headline .btn, #home-headline {
    font-size: 2.5vw;
  }
}

@include media-breakpoint-down(md) {
  #home-headline h2 {
    font-size: 6.1vw;
  }

  #home-headline h1 {
    font-size: 4.5vw;
  }
}

.text-muted-less {
  h3, .h3 {
    font-size: 1.75rem;
    color: #444;
  }
}

/* lists with plus signs */
.features ul, .post ul {
  list-style: none;
}

.features ul li:before, .post ul li:before {
    font-family: 'Font Awesome 5 Free';
    content: '+';
    font-weight: 900;
    margin:0 10px 0 -21px;
}

@include media-breakpoint-down(lg) {
  .extra-scroll-space {
    padding-right: 45px;
  }
}

#iconsDropdownDisplayed {
  z-index: 1050;
}

/* margins on blog */
h2, .h2 {
  margin-top: 1em;
}

/* responsive videos */

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}


.key-points .service-column {
  align-items: initial;
}


/* Snipcart 3.0.2 overrides */
#snipcart {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
}

.snipcart-modal__container {
  left: auto;
  right: 0;
  width: 85vw;
  background: #f8f9faf5;
}

@include media-breakpoint-down(lg) {
  .snipcart-modal__container {
    left: auto;
    right: 0;
    width: 95vw;
  }
}

.snipcart, .snipcart * {
  font-family: $font-family-base;
}

.snipcart__font--secondary, .snipcart__font--subtitle {
  font-family: $font-family-heading;
}

.snipcart-modal, .snipcart-cart-header, .snipcart-cart__content, .snipcart-item-quantity__quantity {
  background-color: transparent;
}

/* .snipcart-cart-button--highlight, .snipcart__box--badge-highlight, .snipcart-form-radio:checked+label:before {
    background-image: -webkit-gradient(linear,left top,right top,from($na-turquoise-dark),to($na-turquoise));
    background-image: linear-gradient(90deg,$na-turquoise-dark,$na-turquoise);
} */

/* .snipcart__actions--link {
  color: $na-turquoise-dark;
}

.snipcart__icon--blue-light path {
    fill: $na-turquoise;
}

.snipcart__icon--blue-dark path {
    fill: $na-turquoise-dark;
}

.snipcart-discount-box__submit {
  color: $na-turquoise-dark;
}

.snipcart-discount-box__form, .snipcart-shipping-rates-list-item--highlight, .snipcart-form-input:focus {
  border-color: $na-turquoise;
}

.snipcart-order__invoice-number--highlight {
  color: $na-turquoise-dark;
} */

.snipcart-cart-summary-expanded-item__custom-fields {
  display: none;
}


#snipcart {
  /* Global */
  --color-link: #{$gold};
  --borderColor-link: transparent;
  --color-link-hover: #{$gold-dark};
  --bgColor-buttonSecondary: lightgray;

  /* Buttons */
  --color-default: $na-turquoise-dark;
  --bgColor-buttonPrimary: #{$na-turquoise-dark}; 

  --bgColor-buttonPrimary-hover: #{$na-turquoise-dark};


  /* Badges */
  --bgColor-badge-active: #{$na-turquoise-dark};
  --color-badge-active: white;

  --color-badge: #{$na-turquoise-dark};
}


.latlong {
  position: fixed;
  bottom: 0;
  border: 1px solid red;
  display: none;
}
/*
.geocoder {
  position: absolute;
  z-index: 1;
  width: 80%;
  left: 20%;
  top: 10px;
}

.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}

#map {
  margin-top: 75px;
}
*/


.overlay {
  pointer-events: none; /* make trim area click through */
  position: absolute;
  left: 30vw;
  top: 9em; /* 110px */
  z-index: 0;
  box-shadow: /* https://brumm.af/shadows */
  0 0px 5.9px rgba(0, 0, 0, 0.16),
  0 0px 14.1px rgba(0, 0, 0, 0.23),
  0 0px 26.5px rgba(0, 0, 0, 0.285),
  0 0px 47.4px rgba(0, 0, 0, 0.34),
  0 0px 88.6px rgba(0, 0, 0, 0.41),
  0 0px 212px rgba(0, 0, 0, 0.57)
  ;
}

/* don't let the attribution be on top of the overlay on smaller screens */
.mapboxgl-ctrl-bottom-right {
    z-index: 0 !important;
  }

@include media-breakpoint-down(lg) {
  .overlay {

  }
}

@include media-breakpoint-down(md) {
  .overlay {

  }
}

.select-area {
  position: absolute;
  top: 0;
  background-color: #bcac76ab;
}

.continue {
  position: absolute;
  bottom: 0px;
  left: 50%;
  pointer-events: all;
  transform: translate(-50%, -50%); /* https://css-tricks.com/quick-css-trick-how-to-center-an-object-exactly-in-the-center/ */
  color: white;
}

@include media-breakpoint-down(md) {
  .continue {
    bottom: -70px;
    width: 80%;
  }
}

.trim-title {
  position: absolute;
  bottom: -45px;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px rgba(0,0,0,0.99);
  background: rgba(0,0,0,0.5);
  padding: 2px 10px;
}

/* map preview */

#map-image {
  /*max-height: 90vh;*/
  box-shadow:
    0 0.8px 13.8px rgba(0, 0, 0, 0.065),
    0 2px 33.3px rgba(0, 0, 0, 0.093),
    0 3.8px 62.6px rgba(0, 0, 0, 0.115),
    0 6.7px 111.7px rgba(0, 0, 0, 0.137),
    0 12.5px 208.9px rgba(0, 0, 0, 0.165),
    0 30px 500px rgba(0, 0, 0, 0.23)
  ;
}

.coords-style {
  font-family: 'urw-din-condensed';
  display: inline-block;
  font-size: 14px;
  float: left;
  clear: both;
  text-transform: uppercase;
  color: white;
  text-shadow:
  0 0px 5px rgba(0, 0, 0, 0.281),
  0 0px 12px rgba(0, 0, 0, 0.404),
  0 0px 22.5px rgba(0, 0, 0, 0.5),
  0 0px 40.2px rgba(0, 0, 0, 0.596),
  0 0px 75.2px rgba(0, 0, 0, 0.719),
  0 0px 180px rgba(0, 0, 0, 1)
;
}

.coords-style .center-label {
  color: #fff;
}

.coords-style .lat, .coords-style .lon {

 }
.coords-style .lon { display:inline-block; margin-left:5px; }

.mapboxgl-ctrl-scale {
  background-color: rgba(0, 0, 0, 0.2) !important;
  font-size: 12px !important;
  font-weight: bold !important;
  border-width: medium 2px 2px !important;
  border-style: none solid solid !important;
  border-color: #fff !important;
  padding: 0 5px !important;
  color: #fff !important;
  box-sizing: border-box !important;


  font-family: 'urw-din' !important;
  text-transform: uppercase !important;

}


#error-message {
    display: none;
}

/*
 * Hide high-res map rendering
 */
.hidden-map {
    overflow: hidden;
    height: 0;
    width: 0;
    position: fixed;
}

#hidden-elements {
  position: absolute;
  left: -9999px;
 }


 #hidden-scale-contained, #hidden-coords-contained {
   position: relative;
   margin: 50px;
   padding: 50px;
 }

#instructions {
  display: none;
  margin-top: -3rem;
}

/*
 * Loading spinner
 * Based on http://codepen.io/lixquid/pen/ybjmr
 */

#loading {
  display: none;
}

#spinner {
    width: 24px;
    height: 24px;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #333;
    border-left: 2px solid #333;
    -webkit-animation: spinner 0.75s ease infinite;
            animation: spinner 0.75s ease infinite;
    border-radius: 100%;
    position: relative;
    top: 8px;
    margin-left: 10px;
    display: inherit;
}
@-webkit-keyframes spinner {
    to {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
}
@keyframes spinner {
    to {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
}


.top-bar {
  background: #3e3e3e;
}