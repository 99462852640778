/*
* ===================================================
*     Pagination
* ===================================================
*/

.pagination {
    border-radius: 0;
}

.page-link {
    margin-right: 5px;
    margin-left: 5px;
    border-top: none;
    border-right: none;
    border-left: none;

    &:hover,
    &:focus {
        border-top: none;
        border-right: none;
        border-left: none;
    }
}

.page-item {
    &:first-child {
        .page-link {
            border-radius: 0;
        }
    }

    &:last-child {
        .page-link {
            border-radius: 0;
        }
    }
}

.page-arrow {
    display: flex;
    width: 3rem;
    height: 3rem;
    margin-right: .5rem;
    margin-left: .5rem;
    color: $gray-500;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
        color: $gray-800;
        border-color: $gray-800;
    }

    &:first-child {
        margin-right: 1rem;
    }

    &:last-child {
        margin-left: 1rem;
    }    
}

.page-icon {
    width: 3rem !important;
    height: 3rem !important;
    stroke-width: 2 !important;
}