/*
* ===================================================
*     SVG icons
* ===================================================
*/

.svg-icon {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    stroke: currentColor;
    stroke-width: 3;

    --layer1: currentColor;
    --layer2: currentColor;

    &.svg-icon-light {
        stroke-width: 2;
    }

    &.svg-icon-heavy {
        stroke-width: 4;
    }

}