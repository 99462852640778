// this is the place for your variable overrides, paste variables from custom/_variables.scss here and set your values

// primary colour is set to false by default to enable multiple colour variants stylesheet creation, 
// change to your brand colour if needed
$primary: false;
$secondary: false;

// This is here just as an example, uncomment the lines here and see it in action 
// $primary:       #ef5285; 
// $secondary:     #429244 !default;
// $font-family-sans-serif:        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-heading:           -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

//
// Custom colors
//

$na-turquoise: #30e4a7;
$na-turquoise-dark: #15a876;
$na-black: #21211e;
$emergency: #7ddeae;

$gold:  #bcac76;
$gold-dark: #877f64;