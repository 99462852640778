/*
* ===================================================
*     Products 
* ===================================================
*/

.product-grid-header {
    display: flex;
    margin-bottom: 1rem;
    color: $gray-600;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    strong {
        color: $gray-700;
    }
}

.product-grid-header-heading {
    color: $gray-800;
}

.product-grid-header-show {
    display: inline-block;
    margin-right: .5rem;
    padding: .3rem;
    color: $gray-600;

    &.active {
        color: theme-color('dark');
        font-weight: bold;
    }

    @include hover-focus() {
        text-decoration: none;
        color: theme-color('dark');
    }
}

.product {
    margin-bottom: $grid-gutter-width;

    @include media-breakpoint-up(md) {
        &:hover {
            .product-hover-overlay {
                visibility: visible;
                transform: translateY(0);
                opacity: 1;
            }

            .product-stars {
                transform: translateY(0);
                opacity: 1;
            }

            .product-swap-image-front {
                opacity: 0;
            }

        }
    }
}

.product-badge {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 5px 8px;
    z-index: 20;

    &+.product-badge {
        top: 60px;

        &+.product-badge {
            top: 110px;

            &+.product-badge {
                top: 160px;
            }
        }
    }
}

.product-image {
    position: relative;
    overflow: hidden;
}

.product-stars {
    position: absolute;
    right: 0;
    bottom: 5px;
    transition: all 0.3s;


    @include media-breakpoint-up(md) {
        transform: translateY(1rem);
        opacity: 0;
    }

}

.product-hover-overlay {
    display: flex;
    width: 100%;
    height: 3rem;
    transition: all 0.3s;
    background: rgba($white, 0.92);
    align-items: center;
    justify-content: space-between;
    z-index: 20;

    .product-hover-overlay-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @include media-breakpoint-up(md) {
        position: absolute;
        bottom: 0;
        left: 0;
        visibility: hidden;
        padding: 0 1rem;
        transform: translateY(3rem);
        opacity: 0;
    }
}

/* Make the container relative */
.product-swap-image {
    position: relative;

    img {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        max-width: 100%;
    }

    .product-swap-image-front {
        position: relative;
        cursor: pointer;
        transition: opacity .5s ease-in-out;
        z-index: 10;
    }
}

.banner {
    position: relative;
    overflow: hidden;
    height: 250px;

    .banner-bg {
        position: absolute;
        bottom: 0;
        width: 100%;
        max-height: 70%;
        text-align: center;
    }

    .banner-text {
        position: relative;
        text-align: center;
        z-index: 10;
    }

    .banner-link::after {

        z-index: 11;
    }


    img.bg-image,
    img.img-fluid {
        transition: all .3s;
    }


    &:hover {

        img.bg-image,
        img.img-fluid {
            transform: scale(1.05);
        }
    }


    @include media-breakpoint-up(sm) {
        height: 250px;
    }

    @include media-breakpoint-up(lg) {
        min-height: 400px;
    }

    @include media-breakpoint-up(xl) {
        min-height: 450px;
    }
}

.card-scale {
    position: relative;
    overflow: hidden;

    .img-scale-container {
        position: relative;
        overflow: hidden;
    }

    img.img-scale {
        transition: all .3s;
    }

    &:hover {
        img.img-scale {
            transform: scale(1.02);
        }
    }


}