@function str-replace($string, $search, $replace: '') {

    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

@function escape-for-svg($color) {

    // To string
    $string: inspect($color);

    @return str-replace($string, '#', '%23');;
}